import { mapState } from "vuex";
var mixins = {
  data() {
    return {
    
      textarea: "",
      // 录图 下方
      qiuIndex: 1,
      ltIndex: 1,
      cltobj: {},
      // ------------------
      tableType: 1,
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [],
      isOpen: false,
      selectionArr: [],
      isFirst: true,
      fontColor: {
        大: "ltred",
        小: "ltblue",
        单: "ltred",
        双: "ltblue",
        和: "ltgreen",
        质: "ltred",
        合: "ltblue",
        龙: "ltred",
        虎: "ltblue",
        蓝: "ltblue",
        红: "ltred",
        绿: "ltgreen",
        春: "ltred",
        夏: "ltblue",
        秋: "ltgreen",
        冬: "ltbro",
      },
      bgColor:{
        单:'bgred',
        双:'bgblue',
        大:'bgred',
        小:'bgblue'
      },
      bgColor1:{
        单:'bgred1',
        双:'bgblue1',
        大:'bgred1',
        小:'bgblue1'
      },
      // zezhao
      zezhao: false,
      weihu: false,
      kaipanTime: "",
      oddData1:[]
    };
  },
  computed: {
    //  预设按钮是否能点击 筛选数据传到下注弹框
    isDisabled() {
      let b = true;
      this.xiazhuList = [];
      this.rowsData.forEach((item) => {
        if (item.money) {
          this.xiazhuList.push(item);
          b = false;
        }
      });
      if (!b) {
        this.$store.commit("setXZList", this.xiazhuList);
      } else {
        this.$store.commit("setXZList", []);
      }
      return b;
    },
    ...mapState([
      "openCount",
      "closeCount",
      "amount",
      "quickSelectedData",
      "quick_c",
      "quick_b",
      "currentGame",
      "userInfo",
      "pan",
      "resetCount",
      "oddData",
      "rclData",
      "lclData",
      "ballArr",
      "ISOPEN",
      "ltcount",
      "zezhaocount",
      "quzezhaocount",
      "updateOdds",
      "tmActive",
      "lhActive",
      "zxActive",
      "zmtActive",
      "bsg",
      "qbsg",
      "wwed",
    ]),
  },
  beforeDestroy() {
    window.clearInterval(window.dataTime);
    window.clearInterval(window.updateOddsTime)
  },
  created() {
   
  },
  mounted() {
    if(this.$options.name!='sanziding'&&this.$options.name!='siziding'){
      window.updateOddsTime=window.setInterval(()=>{
        if(this.currentGame.roomeng){
          this.updateGroupNameOdds()
          this.getBeijingShijian()
        }
      
       },8000)
    }
  
  },
  watch: {
    pan(){
      if(this.$options.name!='sanziding'&&this.$options.name!='siziding'){
        if(this.currentGame.roomeng){
          this.getGroupNameOdds()
        }
    
      }else{
        if(this.currentGame.roomeng){
         this.getAloneOdd();
        }
      }
    },
    updateOdds(val) {
      // console.log("updateOdds更新了", val);
      this.rowsData.forEach((item) => {
        this.gengxinRecordOdds(item.arr, val);
      });
      if(this.$options.name=='wxcerziding'){
        this.gengxinRecordOdds(this.qianbai.arr,val)
        this.gengxinRecordOdds(this.qianshi.arr,val)
        this.gengxinRecordOdds(this.qiange.arr,val)
        this.gengxinRecordOdds(this.baishi.arr,val)
        this.gengxinRecordOdds(this.baige.arr,val)
        this.gengxinRecordOdds(this.shige.arr,val)
      }
      if (this.$options.name == "lhctm") {
        this.gengxinRecordOdds(this.temaA.arr, val);
        this.gengxinRecordOdds(this.temaB.arr, val);
      }
      if (this.$options.name == "lhczmt") {
        this.gengxinRecordOdds(this.zhengte1.arr, val);
        this.gengxinRecordOdds(this.zhengte2.arr, val);
        this.gengxinRecordOdds(this.zhengte3.arr, val);
        this.gengxinRecordOdds(this.zhengte4.arr, val);
        this.gengxinRecordOdds(this.zhengte5.arr, val);
        this.gengxinRecordOdds(this.zhengte6.arr, val);
      }
      if (this.$options.name == "pl3erzidingwei") {
        this.gengxinRecordOdds(this.baishi.arr, val);
        this.gengxinRecordOdds(this.baige.arr, val);
        this.gengxinRecordOdds(this.shige.arr, val);
      }
    },
    currentGame: {
      immediate: true,
      handler(val) {
        if(val.Gametype=='fantan'){
       
          this.getHisList();
        }
        // console.log(val,162)
      
        if (val.InUse == 2) {
          this.weihu = true;
        } else {
          this.weihu = false;
        }
        if(val.roomeng=="canadapc28"){
          this.getBeijingShijian()
         }
        
        if(this.$options.name!='sanziding'&&this.$options.name!='siziding'){
          if(val.roomeng){
            this.getGroupNameOdds()
          }
      
        }else{
          if(val.roomeng){
           this.getAloneOdd();
          }
        }
     
      },
    },
    quzezhaocount() {
      this.zezhao = false;
    },
    zezhaocount() {
      if (this.currentGame.frequency == "day") {
        this.getkaipantime();
      }
    },
    "currentGame.roomeng": {
      immediate: true,
      handler() {
      
        // if (this.isFirst) {
          
          this.isFirst = false;
          if (this.ltArr) {
            if (this.currentGame.frequency == "minite") {
            
              this.getKJHis();
              // window.lttime = window.setTimeout(() => {
              //   this.isFirst = true;
              //   window.clearTimeout(window.lttime);
              // }, 800);
            }
          }
        // }
      },
    },
    ltcount: {
      // immediate: true,
      handler(val) {
        if (this.ltArr) {
          if (this.currentGame.frequency == "minite") {
            this.getKJHis();
            if(this.$options.name=='fantanzsp'){
              this.getHisList();
            }
          
          }
        }
      },
    },
    // 开盘
    openCount(val) {
      window.clearInterval(window.dataTime);
      window.dataTime = null;
      if (
        this.rowsData &&
        this.rowsData[0].arr.length > 0 &&
        this.rowsData.length > 0
      ) {
        this.openPan();
        // console.log("这时候有数据 没走定时器");
      } else {
        window.dataTime = window.setInterval(() => {
          if (
            this.rowsData &&
            this.rowsData[0].arr.length > 0 &&
            this.rowsData.length > 0
          ) {
            this.openPan();
            window.clearInterval(window.dataTime);
            window.dataTime = null;
          }
          // console.log("定时器在走？");
        }, 500);
      }
    },
    // 监听封盘操作
    closeCount(val) {
      if(this.$options.name=='erdingweikx'){
          this.selNumberData.forEach(item=>{
            item.arr.forEach(ele=>{
              ele.selected=false
            })
          })
          this.resultData=[]
      }
      if (this.$route.name == "bgcly") {
        this.selectedArr = [];
      }
      if (this.$route.meta == "连肖连尾") {
        this.rowsData.forEach((item) => {
          item.arr.forEach((ele) => {
            ele.disable = false;
          });
        });
        this.dantuoData.forEach((item) => {
          item.checked = false;
          item.selected = false;
          item.disable = false;
        });
        this.danma = [];
        this.tuoma = [];
        this.dantuoZhuDan = [];
      }
      if (this.$route.meta == "自选不中") {
        this.numArr.forEach((item) => {
          item.selected = false;
        });
      }
      if (this.rowsData && this.rowsData.length > 0) {
        this.rowsData.forEach((item) => {
          item.arr.forEach((ele) => {
            ele.b = false;
            ele.selected = false;
            ele.money = "";

            ele.Odds = "--";
          });
        });
        this.isOpen = false;
        if (this.currentGame.frequency == "day") {
          // this.zezhao = true;
        }
        // console.log("封盘提醒", this.rowsData[0].arr);
        this.$forceUpdate();
      }
    },
    // 重置功能
    resetCount(val) {
    
      this.textarea = "";
      if(this.$options.name=='erzixian'||this.$options.name=='sanzixian'||this.$options.name=='sizixian'
      ||this.$options.name=='pl3erzidingwei'||this.$options.name=='sanziding'){
        for (const key in this.condition) {
          if(key!='dop'){
            this.condition[key]=''
          }
        
        }
      this.daArr.forEach(item=>{
        item.selected=false;
        item.disable=false
      })
      this.xiaoArr.forEach(item=>{
        item.selected=false;
        item.disable=false
      })
      this.danArr.forEach(item=>{
        item.selected=false;
        item.disable=false
      })
      this.shuangArr.forEach(item=>{
        item.selected=false;
        item.disable=false
      })
      this.danxianArr.forEach(item=>{
        item.selected=false;
        item.disable=false
      })
      this.shuangxianArr.forEach(item=>{
        item.selected=false;
        item.disable=false
      })
      this.xiaoxianArr.forEach(item=>{
        item.selected=false;
        item.disable=false
      })
      this.daxianArr.forEach(item=>{
        item.selected=false;
        item.disable=false
      })
      this.selNumberData.forEach(item=>{
        item.disable=false;
        item.arr.forEach(ele=>{
          ele.selected=false
        })
      })
      this.selNumberData1.forEach(item=>{
        item.disable=false;
        item.arr.forEach(ele=>{
          ele.selected=false
        })
      })
      this.dwhenfenNumArr.forEach(item=>{
        item.selected=false
      })
      this.bdwhenfenNumArr.forEach(item=>{
        item.selected=false
      })
      this.dwhefenArr.forEach(item=>{
        item.selected=false
      })
      this.chwzArr.forEach(item=>{
        item.selected=false
        item.disable=false
      })
      this.$nextTick(()=>{
        this.resultData=[]
      })
   
      }
      if (
        this.$options.name == "wxcerziding" ||
        this.$options.name == "wuweierding"
      ) {
        this.kxlist.forEach((item) => {
          item.selected = false;
        });
        this.kxlist2.forEach((item) => {
          item.selected = false;
        });
        this.kxlist1.forEach((item) => {
          item.selected = false;
        });
        this.$nextTick(()=>{
          this.resultData=[]
        })
      }
      if (this.$route.name == "bgcly") {
        this.selectedArr = [];
      }
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          ele.b = false;
          ele.selected = false;
          ele.money = "";
        });
      });
      if (this.$route.meta == "连肖连尾" || this.$options.name == "lhclianma") {
        this.rowsData.forEach((item) => {
          item.arr.forEach((ele) => {
            ele.disable = false;
          });
        });
        this.dantuoData.forEach((item) => {
          item.checked = false;
          item.selected = false;
          item.disable = false;
        });
        this.danma = [];
        this.tuoma = [];
        this.dantuoZhuDan = [];
        this.dantuoOdds = [];
        this.duozuItems = [];
        this.duozuData = [];
        this.duozuOdds = [];
        this.fushiData = [];
        this.fushiTotalNum = 0;
      }
      // ------------
      if (this.$options.name == "lhclianma") {
        this.jcIndex = 0;
        this.jcRows = [];
        for (let index = 0; index < this.titleNum; index++) {
          this.jcRows.push([]);
        }
      }
      // ----------
      if (
        this.$route.meta == "自选不中" ||
        this.$options.name == "syx5lianma" ||
        this.$options.name == "lhclianma" ||
        this.$options.name == "klsflianma"
      ) {
        this.selectionArr = [];
        this.numArr.forEach((item) => {
          item.selected = false;
          item.disable = false;
        });
      }
      if (
        this.$options.name == "syx5lianma" ||
        this.$options.name == "klsflianma"
      ) {
        this.totalNum = 0;
        this.zuheData = [];
      }
      if (this.$options.name == "lhchexiao") {
        this.sxArrData.forEach((item) => {
          item.selected = false;
        });
        this.selectedArr = [];
      }
      if (this.$options.name == "syx5zhixuan") {
        this.jcpZuHeData = [];
        this.jcpTotalNum = 0;
        this.jcRows = [];
        for (let index = 0; index < this.titleNum; index++) {
          this.jcRows.push([]);
        }
        this.numArr1.forEach((item) => {
          item.selected = false;
          item.disable = false;
        });
        this.numArr2.forEach((item) => {
          item.selected = false;
          item.disable = false;
        });
        this.numArr3.forEach((item) => {
          item.selected = false;
          item.disable = false;
        });
      }
      this.$forceUpdate();
    },
    // 监听预设里面的金额
    amount(val) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          if (ele.selected) {
            ele.money = val;
          }
        });
      });
      this.$forceUpdate();
    },
  },
  methods: {
   getBeijingShijian(){
    if(this.currentGame.roomeng=='canadapc28'){
      const currentDate = new Date();
      const beijingOptions = {timeZone: 'Asia/Shanghai'};
      let beijingTime = currentDate.toLocaleString('en-US', beijingOptions);
      // beijingTime='7/11/2024, 7:01:56 PM'
      const beijingHour = new Date(beijingTime).getHours();
      const beijingMinutes = new Date(beijingTime).getMinutes();
      
      if (beijingHour === 19 && beijingMinutes >= 0 && beijingMinutes <= 32) {
          // console.log('当前时间在北京时间的下午7:00到7:30之间。');
          this.weihu=true
      } else {
        this.weihu=false
          // console.log('当前时间不在北京时间的下午7:00到7:30之间。');
      }
    }
   
   },
    getAloneOdd(){
    let obj={
      uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        roomeng:this.currentGame.roomeng,
        pan:this.pan,
        portid:this.portid
    }
      this.$http.post('getcommonportodds',obj).then((res)=>{
        if(res.Status){
          let obj=JSON.parse(res.Msg);
          this.Odds=obj[0].Odds
        
        }
      })
    },
    getGroupNameOdds(){
      if(!this.pan){
          this.$confirm("没有可用盘口，请联系上级开放！", "提示", {
            confirmButtonText: "确定",
            type: "error",
            customClass:'top103',
            duration: 1500,
            showCancelButton: false,
          });
        return
      }
      
      let obj={
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        groupnames:this.groupnames,
        pan:this.pan,
        roomeng:this.currentGame.roomeng
      }
      this.$http.post('getcommongroupodds',obj).then((res)=>{
        if(res.Status){
      let oddArr=JSON.parse(res.Msg)
      oddArr.forEach(item=>{
        item.recordOdds=item.Odds;
        item.Odds='--'
      })
      this.oddData1=oddArr;
      this.$nextTick(() => {
        if (this.ISOPEN) {
          this.$store.commit("OPENPAN");
        }
      });
      // console.log(oddArr,'新街口odds')
        }else{
          this.$message.error(res.Msg);
        }
      })
    },
    updateGroupNameOdds(){
      if(!this.pan){
        this.$confirm("请联系上级开放盘口！", "提示", {
          confirmButtonText: "确定",
          type: "error",
          duration: 1500,
          showCancelButton: false,
        });
      return
    }
      let obj={
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        groupnames:this.groupnames,
        pan:this.pan,
        roomeng:this.currentGame.roomeng
      }
      this.$http.post('getcommongroupodds',obj).then((res)=>{
        if(res.Status){
      let oddArr=JSON.parse(res.Msg)
      this.$store.commit("setupdateOdds", oddArr);
     
  
        }else{
          this.$message.error(res.Msg);
        }
      })
    },
    gengxinRecordOdds(arr, uArr) {
      arr.forEach((ele) => {
        let currentEle = uArr.find((nele) => nele.ResultID == ele.ResultID);
        if (currentEle) {
          ele.recordOdds = currentEle.Odds;
          if (this.ISOPEN) {
            ele.Odds = ele.recordOdds;
          }
        }
      });
      this.$forceUpdate();
    },
    getkaipantime() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        roomeng: this.currentGame.roomeng,
      };
      this.$http.post("getkaipantime", obj).then((res) => {
        if (res.Status) {
          this.zezhao = true;
          let obj = JSON.parse(res.Msg);
        let kaitime= this.substrSome(obj.kaipantime);
        let kaichuo=new Date(kaitime).getTime();
        let nowchuo=new Date().getTime()
        
        if(kaichuo<nowchuo){
          this.kaipanTime ='等待官方发布'
        }else{
          this.kaipanTime =kaitime
        }
     
        
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    changeQiu(index) {
      this.qiuIndex = index;

      this.ltArr = this["lutu" + index]["arr" + this.ltIndex];
    },
    changeLutuData(index) {
      this.ltIndex = index;
      this.ltArr = this["lutu" + this.qiuIndex]["arr" + this.ltIndex];
    },
    initlutuData(arr, str, b) {
      // console.log(arr.length);
      let lastArr = arr[arr.length - 1];

      if (lastArr.length == 5) {
        let littleArr = [str];
        arr.push(littleArr);
      } else {
        if (lastArr.length > 0) {
          if (lastArr[lastArr.length - 1] != str) {
            let littleArr = [str];
            arr.push(littleArr);
          } else {
            lastArr.push(str);
          }
        } else {
          lastArr.push(str);
        }

        // return JSON.parse(JSON.stringify(arr));
      }
    },
    initlutuData1(arr, str, b) {
      // console.log(arr.length);
      let lastArr = arr[arr.length - 1];

      if (lastArr.length == 6) {
        let littleArr = [str];
        arr.push(littleArr);
      } else {
        if (lastArr.length > 0) {
          if (lastArr[lastArr.length - 1] != str) {
            let littleArr = [str];
            arr.push(littleArr);
          } else {
            lastArr.push(str);
          }
        } else {
          lastArr.push(str);
        }

        // return JSON.parse(JSON.stringify(arr));
      }
    },
    buquanArr(arr, larr) {
      larr[0]=[],larr[1]=[],larr[2]=[],larr[3]=[],larr[4]=[];
      let newArr = JSON.parse(JSON.stringify(arr));
      newArr.forEach((eleArr) => {
        for (let i = 0; i < 5; i++) {
          if (!eleArr[i]) {
            eleArr[i] = "";
          }
        }
      });
      if (newArr.length > 40) {
        newArr.splice(0,newArr.length-40);
  
      }
      newArr.forEach((item) => {
        larr[0].push(item[0]);
        larr[1].push(item[1]);
        larr[2].push(item[2]);
        larr[3].push(item[3]);
        larr[4].push(item[4]);
      });
      let leng = larr[0].length;
      if (leng < 40) {
        let num = 40 - leng;
        larr.forEach((item) => {
          for (let index = 0; index < num; index++) {
            item.push("");
          }
        });
      }
      // console.log(larr, "结果");
    },
    buquanArr1(arr, larr) {
      larr[0]=[],larr[1]=[],larr[2]=[],larr[3]=[],larr[4]=[];
      let newArr = JSON.parse(JSON.stringify(arr));
      // console.log(newArr,'ritani')
      newArr.forEach((eleArr) => {
        for (let i = 0; i < 5; i++) {
          if (!eleArr[i]) {
            eleArr[i] = "";
          }
        }
      });
      if (newArr.length > 10) {
        newArr.splice(0,newArr.length-10);
  
      }
   
      newArr.forEach((item) => {
        larr[0].push(item[0]);
        larr[1].push(item[1]);
        larr[2].push(item[2]);
        larr[3].push(item[3]);
        larr[4].push(item[4]);
      });
     
      let leng = larr[0].length;
      if (leng < 10) {
        let num = 10 - leng;
        larr.forEach((item) => {
          for (let index = 0; index < num; index++) {
            item.push("");
          }
        });
      }
      // console.log(larr, "结果");
    },
    buquanArr2(arr, larr) {
      larr[0]=[],larr[1]=[],larr[2]=[],larr[3]=[],larr[4]=[],larr[5]=[];
      let newArr = JSON.parse(JSON.stringify(arr));
      // console.log(newArr,larr,'ritani')
      newArr.forEach((eleArr) => {
        for (let i = 0; i < 6; i++) {
          if (!eleArr[i]) {
            eleArr[i] = "";
          }
        }
      });
      if (newArr.length > 10) {
        newArr.splice(0,newArr.length-11);
  
      }
   
      newArr.forEach((item) => {
        larr[0].push(item[0]);
        larr[1].push(item[1]);
        larr[2].push(item[2]);
        larr[3].push(item[3]);
        larr[4].push(item[4]);
        larr[5].push(item[5]);
      });
     
      let leng = larr[0].length;
      if (leng < 11) {
        let num = 11 - leng;
        larr.forEach((item) => {
          for (let index = 0; index < num; index++) {
            item.push("");
          }
        });
      }
      // console.log(larr, "结果");
    },
    // 开盘提醒
    openPan() {
      // this.zezhao = false;
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          ele.b = false;
          ele.selected = false;
          ele.money = "";
          ele.Odds = ele.recordOdds;
          // console.log(ele.Odds, ele.recordOdds, "两个赔率");
        });
      });
      this.isOpen = true;
      this.$forceUpdate();
    },
    // 选择一种不中类型  连肖连尾
    setActiveIndex(index) {
      if (this.$options.name == "zixuanbuzhong") {
        this.totalNum = 0;
        this.zuheData = [];
        this.selectionArr = [];
      }
      this.bzIndex = index;

      this.numArr.forEach((item) => {
        item.selected = false;
      });
      this.$forceUpdate();
    },
    // input选择  自选不中  连肖连尾
    checkClick(e, item) {
      if (e.target.checked) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      this.$forceUpdate();
    },
    // 切换 不一个类型得 表格
    setTableType(num) {
      this.tableType = num;
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
    closeCheck(ele) {
      ele.b = false;
      this.$forceUpdate();
    },
    inputon(e, obj) {
      console.log(obj);
      obj.selected = true;
      this.$forceUpdate();
    },
    // input聚焦 判断显示快速下注
    showOrHideCheck(e, obj) {
      if (this.quick_b && this.quick_c) {
        this.rowsData.forEach((item) => {
          item.arr.forEach((ele) => {
            if (ele.ResultID == obj.ResultID) {
              ele.b = true;
              ele.selected = true;
            } else {
              ele.b = false;
            }
          });
        });
      } else {
        if (this.amount) {
          obj.money = this.amount;
        }
        obj.selected = true;
      }
      this.$forceUpdate();
    },
    // 连码切换
    selecteLianMa(i, par) {
      this.currentIndex = i;
      this.bzIndex = par.bzNum;
      this.selectionArr = [];
      this.numArr.forEach((item) => {
        item.selected = false;
      });
      this.$forceUpdate();
    },
    // 六合彩连码切换
    LHCslelctLianMa(i, par) {
      this.selectionArr = [];
      this.currentIndex = i;
      this.titleNum = par.bzNum;
      this.jcIndex = 0;
      this.jcRows = [];
      this.fushiTotalNum = 0;
      this.fsXuHeData = [];
      this.jcpZuHeData = [];
      this.duozuItems = [];
      this.duozuData = [];
      this.duozuOdds = [];
      this.fushiData = [];
      this.fushiTotalNum = 0;
      this.danma = [];
      this.tuoma = [];
      this.dantuoZhuDan = [];
      this.dantuoOdds = [];
      for (let index = 0; index < this.titleNum; index++) {
        this.jcRows.push([]);
      }

      this.title = par.label;
      this.titleId = par.ResultID;
      this.numArr.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
      this.dantuoData.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
      this.$forceUpdate();
    },
    // 选中快捷金额
    setCurrentName(item, num) {
      item.money = num;
      item.b = false;
      item.selected = true;

      this.$forceUpdate();
    },
    closeCheck(ele) {
      ele.b = false;
      this.$forceUpdate();
    },
    //   点击当前li
    setCurrentSelected(ele) {
      if (this.$options.name == "syx5lianma") {
        if (!ele.selected) {
          if (this.bzIndex < 6 && this.selectionArr.length == 8) {
            this.$message.warning("已超出最大项目数");
            return;
          }
          if (this.bzIndex >= 6 && this.selectionArr.length == 10) {
            this.$message.warning("已超出最大项目数");
            return;
          }
        }
      }
      if (this.$options.name == "klsflianma") {
        if (!ele.selected) {
          if (this.selectionArr.length == 10) {
            this.$message.warning("已超出最大项目数");
            return;
          }
        }
      }

      if (this.$options.name == "zixuanbuzhong") {
        if (!ele.selected) {
          if (this.bzIndex == 5 || this.bzIndex == 6 || this.bzIndex == 7) {
            if (this.selectionArr.length >= 10) {
              this.$message.warning("已超出最大项目数");
              return;
            }
          } else if (this.bzIndex == 8) {
            if (this.selectionArr.length >= 11) {
              this.$message.warning("已超出最大项目数");
              return;
            }
          } else if (this.bzIndex == 9) {
            if (this.selectionArr.length >= 12) {
              this.$message.warning("已超出最大项目数");
              return;
            }
          } else {
            if (this.selectionArr.length >= 13) {
              this.$message.warning("已超出最大项目数");
              return;
            }
          }
        }
      }
      if (ele.selected) {
        ele.selected = false;
        ele.money = "";
      } else {
        ele.selected = !ele.selected;
        ele.money = ele.selected ? this.amount : "";
      }
      if (this.numArr && this.numArr.length > 0) {
        this.selectionArr = [];
        this.numArr.forEach((item) => {
          if (item.selected) {
            this.selectionArr.push(item);
          }
        });
      }
      this.$forceUpdate();
    },
  },
};
export default mixins;
