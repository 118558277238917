import { mapState } from "vuex";
function cmn(m, n, currentIndex = 0, choseArr = [], result = []) {
  let mLen = m.length;
  // 可选数量小于项所需元素的个数，则递归终止
  if (currentIndex + n > mLen) {
    return;
  }
  for (let i = currentIndex; i < mLen; i++) {
    // n === 1的时候，说明choseArr在添加一个元素，就能生成一个新的完整项了。
    // debugger
    if (n === 1) {
      // 再增加一个元素就能生成一个完整项，再加入到结果集合中
      result.push([...choseArr, m[i]]);
      // 继续下一个元素生成一个新的完整项
      i + 1 < mLen && cmn(m, n, i + 1, choseArr, result);
      break;
    }
    // 执行到这，说明n > 2，choseArr还需要两个以上的元素，才能生成一个新的完整项。则递归，往choseArr添加元素
    cmn(m, n - 1, i + 1, [...choseArr, m[i]], result);
  }
  return result;
}
var mixins = {
  data() {
    return {
      cqLabel:{
        chu:'除',
        qu:'取',
        '':''
      },
      sdanLabel:'',
      sshuangLabel:'',
      sxiaoLabel:'',
      sdaLabel:'',
      danxianlabel:'',
      shuangxianlabel:'',
      xiaoxianlabel:'',
      daxianlabel:'',
      condition: {
        dop: 1, //定位置还是配数全转 1:定位置   2:配数全传
        dwhf: "", //定位合分：1  什么都不选中或定位选多了:''
        bdwhf:'',//不定位合分:2 3  什么都不选中或定位选多了:''
        qz: "", //全转 输入框
        sj: "", //上奖 输入框
        pc: "", //排除 输入框
        fw1:'',
        fw2:'',
        hancq: "",//含 除取
        haninput:'',//含 输入框
        fushiinput:'',//复式 输入框
        sc:'',//双重
        ssc:'',//双双重
        sanchong:'',//三重
        sichong:'',//四重
        xd2:'',//二兄第
        xd3:'',//三兄弟
        xd4:'',//四兄弟
        ds:'',//对数
        dsinput1:'',//对数后面输入框
        dsinput2:'',//对数后面输入框
        dsinput3:'',//对数后面输入框
        dsinput4:'',//对数后面输入框
        dsinput5:'',//对数后面输入框
        dancq:'',//单 除取
        shuangcq:'',//双 除取
        xiaocq:'',//小 除取
        dacq:'',//大 除取
      },
      // 大----------------
      daKey:'',
      daIndexArr:[],
      daArr:[
        {selected:false,disable:false,name:'qian'},
        {selected:false,disable:false,name:'bai'},
        {selected:false,disable:false,name:'shi'},
        {selected:false,disable:false,name:'ge'},
      ],
      // 小------------------------
      xiaoKey:'',
      xiaoIndexArr:[],
      xiaoArr:[
        {selected:false,disable:false,name:'qian'},
        {selected:false,disable:false,name:'bai'},
        {selected:false,disable:false,name:'shi'},
        {selected:false,disable:false,name:'ge'},
      ],
      // 双---------------------------------------
      shuangKey:'',
      shuangIndexArr:[],
      shuangArr:[
        {selected:false,disable:false,name:'qian'},
        {selected:false,disable:false,name:'bai'},
        {selected:false,disable:false,name:'shi'},
        {selected:false,disable:false,name:'ge'},
      ],
      // --单-----------------------------------------
      danKey:'',
      danIndexArr:[],
      danArr:[
        {selected:false,disable:false,name:'qian'},
        {selected:false,disable:false,name:'bai'},
        {selected:false,disable:false,name:'shi'},
        {selected:false,disable:false,name:'ge'},
    ],
    
    // -----------------------------------------------------------
      zhudan_way: false,//
      // 排列3  一字定
      selNumberInput0:[
        {title:'佰',numbervalue:''},
        {title:'拾',numbervalue:''},
        {title:'个',numbervalue:''}],
        selNumberData0: [
          
          {
            title: "佰",
            eng: "bai",
            disable: false,
            chuqu: "qu",
            arr: [
              { num: "0", selected: false },
              { num: "1", selected: false },
              { num: "2", selected: false },
              { num: "3", selected: false },
              { num: "4", selected: false },
              { num: "5", selected: false },
              { num: "6", selected: false },
              { num: "7", selected: false },
              { num: "8", selected: false },
              { num: "9", selected: false },
            ],
          },
          {
            title: "拾",
            eng: "shi",
            disable: false,
            chuqu: "qu",
            arr: [
              { num: "0", selected: false },
              { num: "1", selected: false },
              { num: "2", selected: false },
              { num: "3", selected: false },
              { num: "4", selected: false },
              { num: "5", selected: false },
              { num: "6", selected: false },
              { num: "7", selected: false },
              { num: "8", selected: false },
              { num: "9", selected: false },
            ],
          },
          {
            title: "个",
            eng: "ge",
            disable: false,
            chuqu: "qu",
            arr: [
              { num: "0", selected: false },
              { num: "1", selected: false },
              { num: "2", selected: false },
              { num: "3", selected: false },
              { num: "4", selected: false },
              { num: "5", selected: false },
              { num: "6", selected: false },
              { num: "7", selected: false },
              { num: "8", selected: false },
              { num: "9", selected: false },
            ],
          },
        ],
      //   五星彩   定位置 二字定
      selNumberData: [
        {
          title: "仟",
          eng: "qian",
          disable: false,
          chuqu: "qu",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
        {
          title: "佰",
          eng: "bai",
          disable: false,
          chuqu: "qu",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
        {
          title: "拾",
          eng: "shi",
          disable: false,
          chuqu: "qu",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
        {
          title: "个",
          eng: "ge",
          disable: false,
          chuqu: "qu",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
      ],
      selNumberInput:[
        {numbervalue:'',title:'仟'},
        {numbervalue:'',title:'佰'},
        {numbervalue:'',title:'拾'},
        {numbervalue:'',title:'个'},
      ],
     
   
      // 不定位合分 号码
      bdwhenfenNumArr: [
        { label: "0", selected: false },
        { label: "1", selected: false },
        { label: "2", selected: false },
        { label: "3", selected: false },
        { label: "4", selected: false },
        { label: "5", selected: false },
        { label: "6", selected: false },
        { label: "7", selected: false },
        { label: "8", selected: false },
        { label: "9", selected: false },
      ],
      bdwhenfennumInput:'',
      dop: 1, //1: 定位置  2:配数全转
      dcq: "qu", //定位置 除取
      pcq: "", //配数全转 除取
      yxEngArr: [], //记录点击了几行数据
      resultData: [], //最终选中的数据
      recordData: [],
      // --------------------------------------------------------
      dwhefenArr: [
        { selected: false, disable: false },
        { selected: false, disable: false },
        { selected: false, disable: false },
        { selected: false, disable: false },
      ], //选择位置
      yxIndexArr: [], //定位合分 选择的 index
         // 定位合分 号码
         dwhenfenNumArr: [
          { label: "0", selected: false },
          { label: "1", selected: false },
          { label: "2", selected: false },
          { label: "3", selected: false },
          { label: "4", selected: false },
          { label: "5", selected: false },
          { label: "6", selected: false },
          { label: "7", selected: false },
          { label: "8", selected: false },
          { label: "9", selected: false },
        ],
        dwhenfennumInput:'',
      // ----------------------------------------------------------
        // --------------------------------------------------------
        dwhefenArr1: [
          { selected: false, disable: false },
          { selected: false, disable: false },
          { selected: false, disable: false },
          { selected: false, disable: false },
        ], //选择位置
        yxIndexArr1: [], //定位合分 选择的 index
           // 定位合分 号码
           dwhenfenNumArr1: [
            { label: "0", selected: false },
            { label: "1", selected: false },
            { label: "2", selected: false },
            { label: "3", selected: false },
            { label: "4", selected: false },
            { label: "5", selected: false },
            { label: "6", selected: false },
            { label: "7", selected: false },
            { label: "8", selected: false },
            { label: "9", selected: false },
          ],
          dwhenfennumInput1:'',
        // ----------------------------------------------------------
      
      dwhefenArr2: [
        { selected: false, disable: false },
        { selected: false, disable: false },
        { selected: false, disable: false },
        { selected: false, disable: false },
      ], //选择位置
      yxIndexArr2: [], //定位合分 选择的 index
         // 定位合分 号码
         dwhenfenNumArr2: [
          { label: "0", selected: false },
          { label: "1", selected: false },
          { label: "2", selected: false },
          { label: "3", selected: false },
          { label: "4", selected: false },
          { label: "5", selected: false },
          { label: "6", selected: false },
          { label: "7", selected: false },
          { label: "8", selected: false },
          { label: "9", selected: false },
        ],
        dwhenfennumInput2:'',
      // ----------------------------------------------------------
        // --------------------------------------------------------
        dwhefenArr3: [
          { selected: false, disable: false },
          { selected: false, disable: false },
          { selected: false, disable: false },
          { selected: false, disable: false },
        ], //选择位置
        yxIndexArr3: [], //定位合分 选择的 index
           // 定位合分 号码
           dwhenfenNumArr3: [
            { label: "0", selected: false },
            { label: "1", selected: false },
            { label: "2", selected: false },
            { label: "3", selected: false },
            { label: "4", selected: false },
            { label: "5", selected: false },
            { label: "6", selected: false },
            { label: "7", selected: false },
            { label: "8", selected: false },
            { label: "9", selected: false },
          ],
          dwhenfennumInput3:'',
        // ----------------------------------------------------------
      zongIndexArr: [0, 1, 2, 3],
      chwzIndexArr: [], //乘号位置  选择的 index
      chwzIndexArr1: [], //乘号位置 可用的Arr
      nodwhefen: false, //不定位合分
      hefenNum: "", //两数合
      chwzArr: [
        { selected: false, disable: false },
        { selected: false, disable: false },
        { selected: false, disable: false },
        { selected: false, disable: false },
      ], //乘号位置
    };
  },
  computed: {
    ...mapState(["currentGame", "userInfo", "pan","resetCount",'closeCount']),
    isds(){
      let b=false;
      if(this.condition.dsinput1==''&&this.condition.dsinput2==''&&this.condition.dsinput3==''&&this.condition.dsinput4==''&&this.condition.dsinput5==''){
        b=true
      }
      return b
    }
  },
  created() {
    if(this.isXian){
      this.condition.dop=2;
      this.pcq = "qu"
    }
    
  },
  mounted() {},
  watch: {
   
    // 不定位合分
    bdwhenfennumInput(val){
      if(val){
        let arr=val.split('');
        this.bdwhenfenNumArr.forEach(item=>{
          let hadnum=arr.find(numstr=>numstr==item.label);
          if(hadnum){
            item.selected=false
          }else{
            item.selected=true
          }
          this.setdwhefenNum1(item)
        })
      }else{
        this.bdwhenfenNumArr.forEach(item=>{
          
            item.selected=true
          
          this.setdwhefenNum1(item)
        })
      }
    },
    // 定位合分 0
    dwhenfennumInput(val){
      
      if(val){
        let arr=val.split('');
        this.dwhenfenNumArr.forEach(item=>{
          let hadnum=arr.find(numstr=>numstr==item.label);
          if(hadnum){
            item.selected=false
          }else{
            item.selected=true
          }
          this.setdwhefenNum(item)
        })
      }else{
        this.dwhenfenNumArr.forEach(item=>{
          
            item.selected=true
          
          this.setdwhefenNum(item)
        })
      }
    },
     // 定位合分 1
     dwhenfennumInput1(val){
      
      if(val){
        let arr=val.split('');
        this.dwhenfenNumArr1.forEach(item=>{
          let hadnum=arr.find(numstr=>numstr==item.label);
          if(hadnum){
            item.selected=false
          }else{
            item.selected=true
          }
          this.setdwhefenNum(item)
        })
      }else{
        this.dwhenfenNumArr1.forEach(item=>{
          
            item.selected=true
          
          this.setdwhefenNum(item)
        })
      }
    },
      // 定位合分 1
      dwhenfennumInput2(val){
      
        if(val){
          let arr=val.split('');
          this.dwhenfenNumArr2.forEach(item=>{
            let hadnum=arr.find(numstr=>numstr==item.label);
            if(hadnum){
              item.selected=false
            }else{
              item.selected=true
            }
            this.setdwhefenNum(item)
          })
        }else{
          this.dwhenfenNumArr2.forEach(item=>{
            
              item.selected=true
            
            this.setdwhefenNum(item)
          })
        }
      },
      // 定位合分 1
      dwhenfennumInput3(val){
      
        if(val){
          let arr=val.split('');
          this.dwhenfenNumArr3.forEach(item=>{
            let hadnum=arr.find(numstr=>numstr==item.label);
            if(hadnum){
              item.selected=false
            }else{
              item.selected=true
            }
            this.setdwhefenNum(item)
          })
        }else{
          this.dwhenfenNumArr3.forEach(item=>{
            
              item.selected=true
            
            this.setdwhefenNum(item)
          })
        }
        
      },
    // 取配 输入框
    selNumberInput1:{
      deep:true,
      handler(val){
        val.forEach((item,index)=>{
          let item1=this.selNumberData1[index]
        if(item.numbervalue){
          let arr=item.numbervalue.split('')
      
                
            item1.arr.forEach(ele=>{
              let kn=arr.find(numstr=>numstr==ele.num);
              if(kn){
                ele.selected =false
               
              }else{
                ele.selected =true   
              }
              this.setNumSel1(item1,ele)
            })
        }else{
          
          item1.arr.forEach(ele=>{
              ele.selected =true
              this.setNumSel1(item1,ele)
          })
        }
      })
      }
    },
    // 定位 输入框
    selNumberInput:{
   deep:true,
   handler(val){
    
    val.forEach((item,index)=>{
        let item1=this.selNumberData[index]
      if(item.numbervalue){
        let arr=item.numbervalue.split('')
    
              
          item1.arr.forEach(ele=>{
            let kn=arr.find(numstr=>numstr==ele.num);
            if(kn){
              ele.selected =false
             
            }else{
              ele.selected =true   
            }
            this.setNumSel(item1,ele)
          })
      }else{
        item1.disable=false
        item1.arr.forEach(ele=>{
            ele.selected =true
            this.setNumSel(item1,ele)
        })
      }
    })
   }
    },
    resetCount(){
      // 排列3的  一字定
     if(this.$options.name=='1zdkx'){
      this.resetyZDCondition()
     }else{
      this.resetAllCondition()
     }
   
  
      this.$nextTick(()=>{
        this.resultData=[]
      })
    },
    amount(){
      if(!this.nofuzhi){
        this.fuzhiMoney()
      }
  
    },
    resultData(val){
      if(!this.nofuzhi){
        this.fuzhiMoney()
      }
    },
    // 监听 大 Arr
    daArr:{
      deep:true,
      handler(val){
        this.daKey='';
        this.daIndexArr=[];
        let arr=val.filter(item=>item.selected);
        if(arr.length==this.yxLen){
          val.forEach((item,index)=>{
            if (!item.selected) {
              item.disable=true
            }else{
              this.daKey+=item.name;
              this.daIndexArr.push(index)
              item.disable=false
            }
          })
        }else{
          val.forEach((item,index)=>{
              item.disable=false
              if(item.selected){
                this.daIndexArr.push(index)
              }
          })
        }
        if(this.daIndexArr.length>0){
          let strArr=['X','X','X','X'];
          this.daIndexArr.forEach(eindex=>{
            strArr[eindex]='大'
          })
          this.sdaLabel=strArr.join('')
        }else{
          this.sdaLabel=''
        }
    //    this.filterAllConditionData();
        this.$forceUpdate();
      }
    },
    // 监听 小 Arr
    xiaoArr:{
      deep:true,
      handler(val){
        this.xiaoKey='';
        this.xiaoIndexArr=[];
        let arr=val.filter(item=>item.selected);
        if(arr.length==this.yxLen){
          val.forEach((item,index)=>{
            if (!item.selected) {
              item.disable=true
            }else{
              this.xiaoKey+=item.name;
              this.xiaoIndexArr.push(index)
              item.disable=false
            }
          })
        }else{
          val.forEach((item,index)=>{
              item.disable=false
              if(item.selected){
                this.xiaoIndexArr.push(index)
              }
          })
        }
        if(this.xiaoIndexArr.length>0){
          let strArr=['X','X','X','X'];
          this.xiaoIndexArr.forEach(eindex=>{
            strArr[eindex]='小'
          })
          this.sxiaoLabel=strArr.join('')
        }else{
          this.sxiaoLabel=''
        }
      //  this.filterAllConditionData();
        this.$forceUpdate();
      }
    },
    // 监听 双 Arr
    shuangArr:{
      deep:true,
      handler(val){
        this.shuangKey='';
        this.shuangIndexArr=[];
        let arr=val.filter(item=>item.selected);
        if(arr.length==this.yxLen){
          val.forEach((item,index)=>{
            if (!item.selected) {
              item.disable=true
            }else{
              this.shuangKey+=item.name;
              this.shuangIndexArr.push(index)
              item.disable=false
            }
          })
        }else{
          val.forEach((item,index)=>{
              item.disable=false;
              if(item.selected){
                this.shuangIndexArr.push(index)
              }
          })
        }
        if(this.shuangIndexArr.length>0){
          let strArr=['X','X','X','X'];
          this.shuangIndexArr.forEach(eindex=>{
            strArr[eindex]='双'
          })
          this.sshuangLabel=strArr.join('')
        }else{
          this.sshuangLabel=''
        }
      //  this.filterAllConditionData();
        this.$forceUpdate();
       
      }
    },
    // 监听 单 Arr
    danArr:{
      deep:true,
      handler(val){
        this.danKey='';
        this.danIndexArr=[];
        let arr=val.filter(item=>item.selected);
        if(arr.length==this.yxLen){
          val.forEach((item,index)=>{
            if (!item.selected) {
              item.disable=true
            }else{
              this.danKey+=item.name;
              this.danIndexArr.push(index)
              item.disable=false
            }
          })
        }else{
          val.forEach((item,index)=>{
              item.disable=false
              if(item.selected){
                this.danIndexArr.push(index)
              }
          })
        }
        if(this.danIndexArr.length>0){
          let strArr=['X','X','X','X'];
          this.danIndexArr.forEach(eindex=>{
            strArr[eindex]='单'
          })
          this.sdanLabel=strArr.join('')
        }else{
          this.sdanLabel=''
        }
       // this.filterAllConditionData();
        
        this.$forceUpdate();
       
      }
    },
          // 监听 大 现 Arr
   daxianArr:{
            deep:true,
            handler(val){
              this.daIndexArr=[];
              let arr=val.filter(item=>item.selected);
              if(arr.length>0){
                val.forEach((item,index)=>{
                  if(item.selected){
                    this.daIndexArr.push(index)
                  }
                })
              }
              let strArr=[];
              if(this.daIndexArr.length>0){
                for (let index = 0; index < this.xianzi; index++) {
                strArr.push('#')
                }
           this.daIndexArr.forEach(eindex=>{
            strArr[eindex]='大'
           })
            this.daxianlabel=strArr.join('')
              }else{
                this.daxianlabel=''
              }
             
             // this.filterAllConditionData();
              
              this.$forceUpdate();
            }
          },
       // 监听 小 现 Arr
       xiaoxianArr:{
        deep:true,
        handler(val){
          this.xiaoIndexArr=[];
          let arr=val.filter(item=>item.selected);
          if(arr.length>0){
            val.forEach((item,index)=>{
              if(item.selected){
                this.xiaoIndexArr.push(index)
              }
            })
          }
          let strArr=[];
              if(this.xiaoIndexArr.length>0){
                for (let index = 0; index < this.xianzi; index++) {
                strArr.push('#')
                }
           this.xiaoIndexArr.forEach(eindex=>{
            strArr[eindex]='小'
           })
            this.xiaoxianlabel=strArr.join('')
              }else{
                this.xiaoxianlabel=''
              }
         // this.filterAllConditionData();
          
          this.$forceUpdate();
        }
      },
      // 监听 单 现 Arr
      shuangxianArr:{
        deep:true,
        handler(val){
          this.shuangIndexArr=[];
          let arr=val.filter(item=>item.selected);
          if(arr.length>0){
            val.forEach((item,index)=>{
              if(item.selected){
                this.shuangIndexArr.push(index)
              }
            })
          }
          let strArr=[];
              if(this.shuangIndexArr.length>0){
                for (let index = 0; index < this.xianzi; index++) {
                strArr.push('#')
                }
           this.shuangIndexArr.forEach(eindex=>{
            strArr[eindex]='双'
           })
            this.shuangxianlabel=strArr.join('')
              }else{
                this.shuangxianlabel=''
              }
         // this.filterAllConditionData();
          
          this.$forceUpdate();
        }
      },
    // 监听 单 现 Arr
    danxianArr:{
      deep:true,
      handler(val){
        this.danIndexArr=[];
        let arr=val.filter(item=>item.selected);
        if(arr.length>0){
          val.forEach((item,index)=>{
            if(item.selected){
              this.danIndexArr.push(index)
            }
          })
        }
        let strArr=[];
              if(this.danIndexArr.length>0){
                for (let index = 0; index < this.xianzi; index++) {
                strArr.push('#')
                }
           this.danIndexArr.forEach(eindex=>{
            strArr[eindex]='单'
           })
            this.danxianlabel=strArr.join('')
              }else{
                this.danxianlabel=''
              }
       // this.filterAllConditionData();
        
        this.$forceUpdate();
      }
    },
 
    // 监听定位置 除取
    dcq(val) {
      if (this.yxEngArr.length > 0) {
      //  this.filterAllConditionData();
      }
    },
    // 监听配数全传 除取
    pcq(val) {
   //   this.filterAllConditionData();
    },
    // 监听当前选中几行，禁用其他行
    yxEngArr(val) {
   
    //  this.filterAllConditionData();
      if (val.length == this.yxLen) {
        this.selNumberData.forEach((item) => {
          let eng = val.find((eleEng) => eleEng == item.eng);
          if (eng) {
            item.disable = false;
          } else {
            item.disable = true;
          }
        });
      } else {
        //    不禁用其他行
        this.selNumberData.forEach((item) => {
          item.disable = false;
        });
      }
    },
    // 监听 乘号位置 选中的位置 仟 佰 拾 个
    chwzIndexArr(val) {
      let indexArr = [];
  
      this.zongIndexArr.forEach((eleindex) => {
        let c_bool = val.some((vindex) => eleindex == vindex);
        if (!c_bool) {
          indexArr.push(eleindex);
        }
      });
      this.chwzIndexArr1 = indexArr;
    },
    chwzIndexArr1(val) {
     // this.filterAllConditionData();
    
      if (val.length == this.chLen) {
        this.chwzArr.forEach((item, index) => {
          let numIndex = this.chwzIndexArr.findIndex((elei) => elei == index);
          if (numIndex != -1) {
            item.disable = false;
          } else {
            item.disable = true;
          }
        });
      } else {
        this.chwzArr.forEach((item) => {
          item.disable = false;
        });
      }
    },
    // 监听合分定位置 选中的位置 仟 佰 拾 个？
    yxIndexArr(val) {
      if (val.length > 0) {
        this.condition.dwhf = 1;
        //清空不定位合分数据
        this.nodwhefen = false;
        this.henum = "";
        this.bdwhenfenNumArr.forEach((item) => {
          item.selected = false;
        });
      }
      if (val.length == this.yxLen) {
        this.condition.dwhf = 1;
        this.dwhefenArr.forEach((item, index) => {
          let numIndex = val.findIndex((elei) => elei == index);
          if (numIndex != -1) {
            item.disable = false;
          } else {
            item.disable = true;
          }
        });
      } else {
        this.dwhefenArr.forEach((item) => {
          item.disable = false;
        });
        if(this.yxIndexArr.length==0&&this.yxIndexArr1.length==0&&this.yxIndexArr2.length==0&&this.yxIndexArr3.length==0){
          this.condition.dwhf = "";
         
        }
       
      }
     // this.filterAllConditionData();
    },
      // 监听合分定位置 选中的位置 仟 佰 拾 个？
      yxIndexArr1(val) {
        if (val.length > 0) {
          this.condition.dwhf = 1;
          //清空不定位合分数据
          this.nodwhefen = false;
          this.henum = "";
          this.bdwhenfenNumArr.forEach((item) => {
            item.selected = false;
          });
        }
        if (val.length == this.yxLen) {
          this.condition.dwhf = 1;
          this.dwhefenArr1.forEach((item, index) => {
            let numIndex = val.findIndex((elei) => elei == index);
            if (numIndex != -1) {
              item.disable = false;
            } else {
              item.disable = true;
            }
          });
        } else {
          this.dwhefenArr1.forEach((item) => {
            item.disable = false;
          });
          if(this.yxIndexArr.length==0&&this.yxIndexArr1.length==0&&this.yxIndexArr2.length==0&&this.yxIndexArr3.length==0){
            this.condition.dwhf = "";
           
          }
         
        }
       // this.filterAllConditionData();
      },
          // 监听合分定位置 选中的位置 仟 佰 拾 个？
     yxIndexArr2(val) {
            if (val.length > 0) {
              this.condition.dwhf = 1;
              //清空不定位合分数据
              this.nodwhefen = false;
              this.henum = "";
              this.bdwhenfenNumArr.forEach((item) => {
                item.selected = false;
              });
            }
            if (val.length == this.yxLen) {
              this.condition.dwhf = 1;
              this.dwhefenArr2.forEach((item, index) => {
                let numIndex = val.findIndex((elei) => elei == index);
                if (numIndex != -1) {
                  item.disable = false;
                } else {
                  item.disable = true;
                }
              });
            } else {
              this.dwhefenArr2.forEach((item) => {
                item.disable = false;
              });
              if(this.yxIndexArr.length==0&&this.yxIndexArr1.length==0&&this.yxIndexArr2.length==0&&this.yxIndexArr3.length==0){
                this.condition.dwhf = "";
               
              }
             
            }
           // this.filterAllConditionData();
          },
            // 监听合分定位置 选中的位置 仟 佰 拾 个？
     yxIndexArr3(val) {
              if (val.length > 0) {
                this.condition.dwhf = 1;
                //清空不定位合分数据
                this.nodwhefen = false;
                this.henum = "";
                this.bdwhenfenNumArr.forEach((item) => {
                  item.selected = false;
                });
              }
              if (val.length == this.yxLen) {
                this.condition.dwhf = 1;
                this.dwhefenArr3.forEach((item, index) => {
                  let numIndex = val.findIndex((elei) => elei == index);
                  if (numIndex != -1) {
                    item.disable = false;
                  } else {
                    item.disable = true;
                  }
                });
              } else {
                this.dwhefenArr3.forEach((item) => {
                  item.disable = false;
                });
                if(this.yxIndexArr.length==0&&this.yxIndexArr1.length==0&&this.yxIndexArr2.length==0&&this.yxIndexArr3.length==0){
                  this.condition.dwhf = "";
                 
                }
               
              }
             // this.filterAllConditionData();
            },
    currentGame: {
      immediate: true,
      handler(val) {},
    },
  },
  methods: {
    removeDuplicates(e,item) {
      let inputValues = item.numbervalue.split('');
   
      let uniqueArray = [...new Set(inputValues)];
   
      item.numbervalue=uniqueArray.join('');
      
      this.$forceUpdate()
    },
    // 重置一字定 
    resetyZDCondition(){
      this.selNumberInput0.forEach(item=>{
        item.numbervalue=''
        
        })
        this.resultData=[]
        this.$forceUpdate();
    },
    // 福彩3d 一字定 筛选数据
    filterYZDData(){
      let resultData=[]
      let count=0;
      this.selNumberInput0.forEach(item=>{
      if(item.numbervalue){
        count++
      }
      })
     if(count==0||count>1){
      this.resultData=[]
     this.$message.warning('没有这样的号码')
       return
     }
      let index1=0;
      let str=''
      this.selNumberInput0.forEach((item,index)=>{
        if(item.numbervalue){
          index1=index
          str=item.numbervalue
        }
      })
      let zingArr=this.yzdData[index1]
     let labelArr=[]
      let selArr=str.split('')
      selArr.forEach(ele=>{
       if(index1==0){
        labelArr.push(ele+'XX')
       }
       if(index1==1){
        labelArr.push('X'+ele+'X')
       }
       if(index1==2){
        labelArr.push('XX'+ele)
       }

      })
      // console.log(this.dcq)
      if(this.dcq=='qu'){
        labelArr.forEach(ele=>{
          let xzItem=zingArr.find(otem=>otem.label==ele)
          if(xzItem){
           resultData.push(xzItem)
          }
          })
      }
      if(this.dcq=='chu'){
        zingArr.forEach(element => {
          let hadIndex=labelArr.findIndex(ele=>ele==element.label)
          if(hadIndex==-1){
            resultData.push(element)
          }
        });
      }
    
   this.resultData=resultData;
  
    },
    fuzhiMoney(){
      let arr=JSON.parse(JSON.stringify(this.resultData));
      if(arr.length>0&&this.amount){
        arr.forEach(ele=>{
          ele.money=Number(this.amount)
          if(this.ISOPEN){
            ele.Odds=ele.recordOdds
          }
        })
        this.rowsData=arr;
      }else{
        this.rowsData=[]
      }
    },
    panduanDsinput(val){
      let b=true;
       if(!this.isNumber(val)){
       return false
       } 
      let str=val+''
      if(str.length!=2){
        return false
      }
      return b
    },
    // 对数后面的输入框
    dsinputchange(e,num){
      let value=e.target.value;
      let bcool=this.isNumber(value)
      if(!bcool){
        this.condition['dsinput'+num]='' 
      }
     // this.filterAllConditionData();
   
      this.$forceUpdate();
    },
    // 点击不定位合分号码
    setdwhefenNum1(kn) {
      kn.selected = !kn.selected;
     // this.filterAllConditionData();
    },

    // 监听不定位合分
    JTBDWHENFEN(parData,hecount) {
      let resultData = [];
      let hecount1=Number(hecount)
      // 不定位合分
      if (this.nodwhefen) {
        let arr = this.bdwhenfenNumArr.filter((item) => item.selected);
        if (arr.length == 0) {
          return parData;
        }

        if (parData.length > 0) {
          parData.forEach((item) => {
            let c = this.bdwhefenPanDuanBool1(item.label, arr,hecount1);
            if (c) {
              resultData.push(item);
            }
          });
        } else {
          // 上面没有筛选得数据
          let allData = JSON.parse(JSON.stringify(this.allData));
          allData.forEach((item) => {
            let c = this.bdwhefenPanDuanBool1(item.label, arr,hecount1);
            if (c) {
              resultData.push(item);
            }
          });
        }
      } else {
        return parData;
      }
      return resultData;
    },
    // 点击定位合分 号码
    setdwhefenNum(kn, index) {
      kn.selected = !kn.selected;
     // this.filterAllConditionData();
    },
    // 监听定位合分
    JTDWHEFEN(parData,str) {
     
      let resultData = [];
      if (this['yxIndexArr'+str].length > this.yxLen) {
        return parData;
      }
      let arr = this['dwhenfenNumArr'+str].filter((item) => item.selected);
      if (arr.length == 0) {
        return parData; //定位合分没有选中 球号
      }
      
      let allData = [];
      if (parData.length > 0) {
        allData = parData;
      } else {
        allData = JSON.parse(JSON.stringify(this.allData));
      }
      allData.forEach(item=>{
        let rbool = this.dwhefenPanDuanBool(item.label, arr,str);
        if(rbool){
          resultData.push(item)
        }
      })
 
     
     
      return resultData;
    },
    // 乘号位置 选位置
    chwzChange(e, item, index) {
      // chwzIndexArr
      let val = e.target.checked;
      item.selected = val;
      let cIndex = this.chwzIndexArr.findIndex((i) => i == index);
      if (cIndex == -1) {
        // 没有记录 当前 点击位置Index
        if (item.selected) {
          this.chwzIndexArr.push(index);
        }
      } else {
        if (!item.selected) {
          this.chwzIndexArr.splice(cIndex, 1);
        }
      }

      this.chwzIndexArr = JSON.parse(JSON.stringify(this.chwzIndexArr));
    },
    // 定位合分 选位置
    dwChange(e, item, index,str) {
      let val = e.target.checked;
      item.selected = val;

      let cIndex = this['yxIndexArr'+str].findIndex((i) => i == index);
      if (cIndex == -1) {
        // 没有记录 当前 点击位置Index
        if (item.selected) {
          this['yxIndexArr'+str].push(index);
        }
      } else {
        if (!item.selected) {
          this['yxIndexArr'+str].splice(cIndex, 1);
        }
      }

      this['yxIndexArr'+str] = JSON.parse(JSON.stringify(this['yxIndexArr'+str]));
     
    },
    // 筛选配数全传 数据
    filterPSQZData(parData) {
      let arr = [];
      let b = false;
      if (this.pcq == "qu") {
        b = true;
      } else if (this.pcq == "chu") {
        b = false;
      }
      arr = this.JTpeishuQu(parData, b);
      return arr;
    },
    // 筛选定位置 数据
    filterDWZData(allData) {
      let arr = [];
      // 定位置 取
      if (this.dcq == "qu") {
        arr = this.getCurrentIndexData(allData);
      } else if (this.dcq == "chu") {
        // 定位置 除
        arr = this.chuCurrentIndexData(allData);
      }
      return arr;
    },
    // 定位置 取
    getCurrentIndexData(allData) {
      let arr = []
      if(this.pl3){
        arr= this.selNumberData1.filter((item) => {
          let b = item.arr.some((ele) => ele.selected);
          return b;
        });
      }else{
        arr= this.selNumberData.filter((item) => {
          let b = item.arr.some((ele) => ele.selected);
          return b;
        });
      }
  

      let mulArr = []; //排列组合 总数居
      let indexArr = [];
      arr.forEach((item) => {
        indexArr.push(this.wzIndex[item.eng]);
        item.numArr = [];
        item.arr.forEach((ele) => {
          if (ele.selected) {
            item.numArr.push(ele.num);
          }
        });
        mulArr.push(item.numArr);
      });

      let hunheArr = this.multiplyData(mulArr);

      let resultData = [];

      allData.forEach((item) => {
        item.dwArr = [];
        indexArr.forEach((i) => {
          item.dwArr.push(item.label[i]);
        });
        let joinstr = item.dwArr.join("");
        let b = hunheArr.some((hunhe) => {
          if (hunhe.length > 1) {
            return joinstr == hunhe.join("");
          } else {
            return joinstr == hunhe;
          }
        });
        if (b) {
          resultData.push(item);
        }
      });

      return resultData;
  
    },
    // 定位置 除
    chuCurrentIndexData(parData) {
      let arr = this.selNumberData.filter((item) => {
        let b = item.arr.some((ele) => ele.selected);
        return b;
      });

      let mulArr = []; //排列组合 选中的

      let indexArr = [];
      let str = "";
      arr.forEach((item) => {
        indexArr.push(this.wzIndex[item.eng]);
        str += item.eng;
        item.numArr = [];

        item.arr.forEach((ele) => {
          if (ele.selected) {
            item.numArr.push(ele.num);
          }
        });
        mulArr.push(item.numArr);
      });

      let hunheArr = this.multiplyData(mulArr);
      let allData = [];

      if (indexArr.length == this.yxLen) {
        allData = JSON.parse(JSON.stringify(this[str].arr));
      } else {
        allData = parData;
      }
      let resultData = [];

      allData.forEach((item) => {
        item.dwArr = [];
        indexArr.forEach((i) => {
          item.dwArr.push(item.label[i]);
        });
        let joinstr = item.dwArr.join("");
        // console.log(joinstr, "joinstr", hunheArr);
        let b = hunheArr.every((hunhe) => {
          if (hunhe.length > 1) {
            return joinstr != hunhe.join("") && joinstr.indexOf("X") == -1;
          } else {
            return joinstr != hunhe && joinstr.indexOf("X") == -1;
          }
        });
        if (b) {
          resultData.push(item);
        }
      });
      return resultData;
    },
    // 大 除取
    dachange(e){
      let val=e.target.value;
      let ischecked=e.target.checked;
      if(ischecked){
        this.condition.dacq=val;
         }else{
       this.condition.dacq=''
         }
       //  this.filterAllConditionData();
    },
    // 小 除取
    xiaochange(e){
      let val=e.target.value;
      let ischecked=e.target.checked;
      if(ischecked){
        this.condition.xiaocq=val;
         }else{
       this.condition.xiaocq=''
         }
      //   this.filterAllConditionData();
    },
    // 双 除取
    shuangchange(e){
      let val=e.target.value;
      let ischecked=e.target.checked;
      if(ischecked){
        this.condition.shuangcq=val;
         }else{
       this.condition.shuangcq=''
         }
       //  this.filterAllConditionData();
    },
    // 单 除取
    danchange(e){
      let val=e.target.value;
      let ischecked=e.target.checked;
      if(ischecked){
        this.condition.dancq=val;
         }else{
       this.condition.dancq=''
         }
      //   this.filterAllConditionData();
    },
    // 对数 change
    dschange(e){
      let val=e.target.value;
      let ischecked=e.target.checked;
      if(ischecked){
     this.condition.ds=val;
      }else{
    this.condition.ds=''
      }
     // this.filterAllConditionData();
    },
   
    // 二兄第 除取
    xd2change(e){
      let val=e.target.value;
      let ischecked=e.target.checked;
      if(ischecked){
       this.condition.xd2=val
      }else{
        this.condition.xd2=''
      }

     // this.filterAllConditionData();
    },
      // 三兄第 除取
      xd3change(e){
        let val=e.target.value;
        let ischecked=e.target.checked;
        if(ischecked){
         this.condition.xd3=val
        }else{
          this.condition.xd3=''
        }
  
      //  this.filterAllConditionData();
      },
        // 四兄第 除取
        xd4change(e){
          let val=e.target.value;
          let ischecked=e.target.checked;
          if(ischecked){
           this.condition.xd4=val
          }else{
            this.condition.xd4=''
          }
    
        //  this.filterAllConditionData();
        },
        // 四重
        sichange(e){
          let val=e.target.value;
          let ischecked=e.target.checked;
          if(ischecked){
            this.condition.sichong=val;
           }else{
             this.condition.sichong='';
           }
           
          // this.filterAllConditionData();
        },
        // 三重
        sanchange(e){
          let val=e.target.value;
          let ischecked=e.target.checked;
          if(ischecked){
            this.condition.sanchong=val;
           }else{
             this.condition.sanchong=''
           }
           
          // this.filterAllConditionData();
        },
        // 双双重 change
        sscchange(e){
          let val=e.target.value;
          let ischecked=e.target.checked;
          if(ischecked){
           this.condition.ssc=val;
          }else{
            this.condition.ssc=''
          }
          
        //  this.filterAllConditionData();
        },
    // 双重 除取 change
    scchange(e){
      let val=e.target.value;
      let ischecked=e.target.checked;
      if(ischecked){
       this.condition.sc=val;
      }else{
        this.condition.sc=''
      }
      
     // this.filterAllConditionData();
    },
    //判断是几兄弟
   isContinuityNum(num,xdnum){
    let buguizeArr=null,buguizeArr1=null,buguizeArr2=null;
     if(xdnum==2){
      // 二兄弟  判断 0和9
      buguizeArr=['0','9'];
      let b=false;
    
      b=this.isContained(num,buguizeArr);
      if(b){
       
        return true
      }
     }
     if(xdnum==3){
      // 三兄弟  判断 0和9
      buguizeArr=['0','9','1'];
      buguizeArr1=['8','9','0']
      let b=false;
      let c=false;
      b=this.isContained(num,buguizeArr);
      c=this.isContained(num,buguizeArr1);
      if(b||c){
       
        return true
      }
     }
     if(xdnum==4){
      // 四兄弟  判断 0和9
      buguizeArr=['0','9','1','2'];
      buguizeArr1=['7','8','9','0'];
      buguizeArr2=['8','9','0','1'];
      let b=false,c=false,d=false;
    
      b=this.isContained(num,buguizeArr);
      c=this.isContained(num,buguizeArr1);
      d=this.isContained(num,buguizeArr2);
      if(b||c||d){
       
        return true
      }
     }
      let array=[];
      if(num instanceof Array){
          array =JSON.parse(JSON.stringify([...num]));
      }else{
          array = Array.from(num.toString())//转换为数组
      }            
      var isContinuation=false;
      let xdcount=0;
      let hunheArr=this.arrange(array);
        isContinuation=hunheArr.some(itemarr=>itemarr.length>=xdnum)
      // array.sort((a,b)=>Number(a)-Number(b));
      // var i=array[0];
    
      // for(var e in array){
      //     if(array[e]!=i){
      //         break;
      //     }
          
      //     xdcount++
      //     i++;
      // }
      // if(xdcount>=xdnum){
      //   isContinuation=true
      // }
      return isContinuation;

    },
   JTDXData(parData,cbool,xdnum){
    let resultData = [];
    let allData = [];
    if (parData.length > 0) {
      allData = parData;
    } else {
      allData = JSON.parse(JSON.stringify(this.allData));
    }
    allData.forEach(item=>{
      let labelD =item.label.split("");
      let labelNumArr = labelD.filter((lele) => this.isNumber(lele));
     let b=this.isContinuityNum(labelNumArr,xdnum);
     if(b==cbool){
      resultData.push(item)
     }

    })
    return resultData
   },
 
    // 含 除取 change
    hanchange(e){
      
      let val=e.target.value;
      let ischecked=e.target.checked;
      if(ischecked){
        this.condition.hancq=val
      }else{
        this.condition.hancq=''
      }
     // this.filterAllConditionData();
      // console.log(this.condition.hancq)
    },
    // 点击当前号码  定位置 二字定
    setNumSel(item, kn) {
      if (item.disable) {
        return;
      }
      kn.selected = !kn.selected;
      let cIndex = this.yxEngArr.findIndex((label) => label == item.eng);
      if (cIndex == -1) {
        //没有记录item.eng
        if (kn.selected) {
          this.yxEngArr.push(item.eng);
        }
      } else {
        // 判断当前行有没有一个选中得
        let b = item.arr.some((ele) => ele.selected);
        if (!b) {
          this.yxEngArr.splice(cIndex, 1);
        }
      }
      this.yxEngArr = JSON.parse(JSON.stringify(this.yxEngArr));
 
    },
    //  配数全传  配数全转  二字定
    setNumSel1(item, kn) {
      kn.selected = !kn.selected;
     // this.filterAllConditionData();
    },
  
    // 二定位 配数全转 取或者除
    JTpeishuQu(allData, cbool) {
      let arr = this.selNumberData1.filter((item) => {
        let b = item.arr.some((ele) => ele.selected);
        return b;
      });

      let resultData = [];
      if (arr.length > 0) {
        let mutArr = [];
        arr.forEach((item) => {
          item.numArr = [];
          item.arr.forEach((ele) => {
            if (ele.selected) {
              item.numArr.push(ele.num);
            }
          });
          mutArr.push(item.numArr);
        });
        let hunheArr = this.multiplyData(mutArr);

        allData.forEach((item) => {
          let b = hunheArr.some((hunhe) => {
            if (hunhe.length > 1) {
              let labelArr = item.label.split("");
              let labelNumArr = labelArr.filter((lele) => this.isNumber(lele));
              let b = this.isContained(labelNumArr, hunhe);
              return b;
            } else {
              return item.label.indexOf(hunhe) != -1;
            }
          });
          if (b == cbool) {
            resultData.push(item);
          }
        });
      }
      return resultData;
    },
    // 切换定位置除取
    dcqChange(e) {
      this.pcq = ""; //配数全转 清空
      this.dop = 1;
      this.condition.dop = 1;
      this.resultData = [];
      this.recordData = [];
      this.selNumberData1.forEach((item) => {
        item.arr.forEach((ele) => {
          ele.selected = false;
        });
      });
    },
    // 全转输入框change
    qzchange() {
     // this.filterAllConditionData();
    },
    fwchange(){
    //  this.filterAllConditionData();
    },
    // 全转输入框取数据
    JTQZData(parData) {
      let paraData = parData;
      let resultData = [];
      let qzStr = this.condition.qz + "";

      if (qzStr.length < this.yxLen) {
        return paraData;
      }
      let numArr = qzStr.split("");

      if (paraData.length > 0) {
        paraData.forEach((item) => {
          let labelD = item.label.split("");
          let labelNumber = labelD.filter((lele) => this.isNumber(lele));
          let b = this.isContained(numArr, labelNumber);
          if (b) {
            resultData.push(item);
          }
        });
      } else {
        // 上面没有筛选得数据
        let allData = JSON.parse(JSON.stringify(this.allData));
        allData.forEach((item) => {
          let labelD = item.label.split("");
          let labelNumber = labelD.filter((lele) => this.isNumber(lele));
          let b = this.isContained(numArr, labelNumber);
          if (b) {
            resultData.push(item);
          }
        });
      }

      return resultData;
    },
    // 上奖输入框 取数据
    JTSJData(parData) {
      let paraData = parData;
      let resultData = [];
      let qzStr = this.condition.sj + "";

      if (qzStr.length < 1) {
        return paraData;
      }
      let numArr = qzStr.split("");

      if (paraData.length > 0) {
        paraData.forEach((item) => {
          let labelD = item.label.split("");
          let labelNumber = labelD.filter((lele) => this.isNumber(lele));
          let b = null;
          if (qzStr.length < this.yxLen) {
            b = this.isContained(labelNumber, numArr);
          } else {
            b = this.isContained(numArr, labelNumber);
          }

          if (b) {
            resultData.push(item);
          }
        });
      } else {
        // 上面没有筛选得数据
        let allData = JSON.parse(JSON.stringify(this.allData));
        allData.forEach((item) => {
          let labelD = item.label.split("");
          let labelNumber = labelD.filter((lele) => this.isNumber(lele));
          let b = null;
          if (qzStr.length < this.yxLen) {
            b = this.isContained(labelNumber, numArr);
          } else {
            b = this.isContained(numArr, labelNumber);
          }
          if (b) {
            resultData.push(item);
          }
        });
      }

      return resultData;
    },
    // 排除输入框 取数据
    JTPCData(parData, cbool) {
      let resultData = [];
      let pcStr = this.condition.pc + "";
      let numArr = pcStr.split("");

      let selNumberData = JSON.parse(JSON.stringify(this.selNumberData1));
      selNumberData.forEach((item) => {
        item.arr.forEach((ele) => {
          ele.selected = false;
        });
      });
      selNumberData.forEach((item, index) => {
        if (index == 0) {
          item.arr.forEach((ele) => {
            let czbool = numArr.some((num) => num == ele.num);
            if (czbool) {
              ele.selected = true;
            }
          });
        }
      });
      let arr = selNumberData.filter((item) => {
        let b = item.arr.some((ele) => ele.selected);
        return b;
      });

      if (arr.length > 0) {
        let mutArr = [];
        arr.forEach((item) => {
          item.numArr = [];
          item.arr.forEach((ele) => {
            if (ele.selected) {
              item.numArr.push(ele.num);
            }
          });
          mutArr.push(item.numArr);
        });
        let hunheArr = this.multiplyData(mutArr);
        let allData = [];
        if (parData.length > 0) {
          allData = parData;
        } else {
          allData = JSON.parse(JSON.stringify(this.allData));
        }
        allData.forEach((item) => {
          let b = hunheArr.some((hunhe) => {
            if (hunhe.length > 1) {
              let labelArr = item.label.split("");
              let labelNumArr = labelArr.filter((lele) => this.isNumber(lele));
              let b = this.isContained(labelNumArr, hunhe);
              return b;
            } else {
              return item.label.indexOf(hunhe) != -1;
            }
          });
          if (b == cbool) {
            resultData.push(item);
          }
        });
      }
      return resultData;
    },
    // 范围值 除取数据
    JTFWData(parData,f1,f2){
      console.log(f1,f2)
      let resultData = [];
    let allData = [];
    if (parData.length > 0) {
      allData = parData;
    } else {
      allData = JSON.parse(JSON.stringify(this.allData));
    }
    allData.forEach((item)=>{
      let labelD = item.label.split("");
      let labelNumber = labelD.filter((lele) => this.isNumber(lele));
      let count=0;
      labelNumber.forEach(numele=>{
        count+=Number(numele)
      })
      if(count>=f1&&count<=f2){
        resultData.push(item)
      }
    })

    return resultData;
    },
     //  对数输入框 
  JTDSINPUTData(parData,dsinputArr,cbool){
    let resultData = [];
    let allData = [];
    if (parData.length > 0) {
      allData = parData;
    } else {
      allData = JSON.parse(JSON.stringify(this.allData));
    }
    let hunheArr=[];
    dsinputArr.forEach(item=>{
      let arr=item.split('');
      hunheArr.push(arr)
    })
    allData.forEach(item=>{
      let labelD = item.label.split("");
      let labelNumber = labelD.filter((lele) => this.isNumber(lele));
      let b=false;
      b=hunheArr.some(itemarr=>{
        return this.isContained(labelNumber,itemarr);
      })
      if(b==cbool){
        resultData.push(item)
      }
    })
    return resultData;
  },
  // 对数 差值是5
  JTDSData(parData,cbool){
    let duishuArr=[['0','5'],['1','6'],['2','7'],['3','8'],['4','9']]
    let resultData = [];
    let allData = [];
    if (parData.length > 0) {
      allData = parData;
    } else {
      allData = JSON.parse(JSON.stringify(this.allData));
    }
    allData.forEach(item=>{
      let labelD = item.label.split("");
      let labelNumber = labelD.filter((lele) => this.isNumber(lele));
      let b=false;
       b=duishuArr.some(itemarr=>{
        return this.isContained(labelNumber,itemarr)
       })
       if(b==cbool){
        resultData.push(item)
       }
    })
   return resultData;
  },
    // 乘号位置 取数据
    JTCHWZData(parData) {
      let resultData = [];
      let allData = [];
      if (parData.length > 0) {
        allData = parData;
      } else {
        allData = JSON.parse(JSON.stringify(this.allData));
      }
      allData.forEach((item) => {
        let label = item.label;
        let b = true;
        this.chwzIndexArr1.forEach((cindex) => {
          if (!this.isNumber(label[cindex])) {
            b = false;
          }
        });
        if (b) {
          resultData.push(item);
        }
      });
      return resultData;
    },
    // 监听 大 除取
    JTDaData(parData,cbool){
      let resultData = [];
      let allData = [];
      if (parData.length > 0) {
        allData = parData;
      } else {
        if(this.yxLen==4||this.isXian){
          allData = JSON.parse(JSON.stringify(this.allData));
        }else{
          allData = JSON.parse(JSON.stringify(this[this.daKey])).arr;
        }
       
      }
      if(this.condition.dacq==''){
        resultData=allData;
      }else{
        let filterData=[];
        filterData= allData.filter(item=>{
          let b=false;
         b= this.daIndexArr.every(rindex=>this.isNumber(item.label[rindex]));
         return b
        });
        filterData.forEach(item=>{
          let b=false;
         
            b=this.daIndexArr.every(elel=>Number(item.label[elel])>4);
          if(b==cbool){
            resultData.push(item);
          }
         })
      }
      return resultData
    },
    // 监听 小 除取
    JTXiaoData(parData,cbool){
      let resultData = [];
      let allData = [];
      if (parData.length > 0) {
        allData = parData;
      } else {
        if(this.yxLen==4||this.isXian){
          allData = JSON.parse(JSON.stringify(this.allData));
        }else{
          allData = JSON.parse(JSON.stringify(this[this.xiaoKey])).arr;
        }
       
      }
      if(this.condition.xiaocq==''){
        resultData=allData;
      }else{
        let filterData=[];
        filterData= allData.filter(item=>{
          let b=false;
         b= this.xiaoIndexArr.every(rindex=>this.isNumber(item.label[rindex]));
         return b
        });
        filterData.forEach(item=>{
          let b=false;
            b=this.xiaoIndexArr.every(elel=>Number(item.label[elel])<=4);
          if(b==cbool){
            resultData.push(item);
          }
         })
      }

return resultData

    },
    // 监听 双 除取
    JTShuangData(parData,cbool){
      let resultData = [];
      let allData = [];
      if (parData.length > 0) {
        allData = parData;
      } else {
        if(this.yxLen==4||this.isXian){
          allData = JSON.parse(JSON.stringify(this.allData));
        }else{
          allData = JSON.parse(JSON.stringify(this[this.shuangKey])).arr;
        }
       
      }
      if(this.condition.shuangcq==''){
        resultData=allData;
      }else{
        let filterData=[];
        filterData= allData.filter(item=>{
          let b=false;
         b= this.shuangIndexArr.every(rindex=>this.isNumber(item.label[rindex]));
         return b
        });
        filterData.forEach(item=>{
          let b=false;
          b=this.shuangIndexArr.every(elel=>Number(item.label[elel])%2==0); 
          if(b==cbool){
            resultData.push(item);
          }
         })
      }
  
     return resultData;

    },
    // 监听 单 除取
    JTDanData(parData,cbool){
      let resultData = [];
      let allData = [];
      if (parData.length > 0) {
        allData = parData;
      } else {
        if(this.yxLen==4||this.isXian){
          allData = JSON.parse(JSON.stringify(this.allData));
        }else{
          allData = JSON.parse(JSON.stringify(this[this.danKey])).arr;
        }
       
      }
      if(this.condition.dancq==''){
       resultData=allData;
      }else{
        let filterData=[];
       
          filterData= allData.filter(item=>{
            let b=false;
           b= this.danIndexArr.every(rindex=>this.isNumber(item.label[rindex]));
           return b
          })
        
       filterData.forEach(item=>{
        let b=false;
         b=this.danIndexArr.every(elel=>Number(item.label[elel])%2!=0);
          
        if(b==cbool){
          resultData.push(item);
        }
       })

      }
      // console.log(allData)

    return resultData
    },
    // 含  取 数据
    JTHANCHUQUData(parData,cbool){
      let resultData = [];
      let allData = [];
      if (parData.length > 0) {
        allData = parData;
      } else {
        allData = JSON.parse(JSON.stringify(this.allData));
      }
      let conStr=this.condition.haninput+'';
     let numArr=conStr.split('');
  
      allData.forEach(item=>{
        let labelD = item.label.split("");
        let labelNumber = labelD.filter((lele) => this.isNumber(lele));
        let b=false;
       
       labelNumber.forEach(labnum=>{
       let c=numArr.some(inum=>inum==labnum)
       if(c){
        b=true
       }
       })
      if(b==cbool){
        resultData.push(item)
      }

      })


    return resultData;
    },
    // 复式 除取数据
    JTFUSHIData(parData,cbool){
      let resultData = [];
      let allData = [];
      // console.log(parData,parData.length)
      if (parData.length > 0) {
        allData = parData;
      } else {
        allData = JSON.parse(JSON.stringify(this.allData));
      }
      let conStr=this.condition.fushiinput+'';
     let numArr=conStr.split('');
     allData.forEach(item=>{
      let labelD = item.label.split("");
      let labelNumber = labelD.filter((lele) => this.isNumber(lele));
    let b=this.isContainedFuShi(numArr,labelNumber)
     if(b==cbool){
      resultData.push(item)
     }
     })
    return resultData;
    },
    // 四重 除取数据
    JTSICHONGData(parData,cbool){
      let resultData = [];
      let allData = [];
      if (parData.length > 0) {
        allData = parData;
      } else {
        allData = JSON.parse(JSON.stringify(this.allData));
      }
      allData.forEach(item=>{
        let labelD = item.label.split("");
        let labelNumber = labelD.filter((lele) => this.isNumber(lele));
      let b=false;
      if(Array.from(new Set(labelNumber)).length==1){
        b=true
      }
       if(b==cbool){
        resultData.push(item)
       }
       })
      return resultData;
    },
    // 三重 除取数据
    JTSANCHONGData(parData,cbool){
      let resultData = [];
      let allData = [];
      if (parData.length > 0) {
        allData = parData;
      } else {
        allData = JSON.parse(JSON.stringify(this.allData));
      }
     allData.forEach(item=>{
      let labelD = item.label.split("");
      let labelNumber = labelD.filter((lele) => this.isNumber(lele));
       let b=false;
     
        let numObj = labelNumber.reduce(function (prev, next) {
          prev[next] = prev[next] + 1 || 1;
  
          return prev;
        }, {});
      
        for (const key in numObj) {
           if(numObj[key]>=3){
            b=true
           }
        }
      if(b==cbool){
        resultData.push(item)
      }
     })

    
    return resultData;
    },
    // 双双重 除取数据
    JTSSCData(parData,cbool){
      let resultData = [];
      let allData = [];
      if (parData.length > 0) {
        allData = parData;
      } else {
        allData = JSON.parse(JSON.stringify(this.allData));
      }
      allData.forEach(item=>{
        let labelD = item.label.split("");
        let labelNumber = labelD.filter((lele) => this.isNumber(lele));
    let b=false;
    let numObj = labelNumber.reduce(function (prev, next) {
      prev[next] = prev[next] + 1 || 1;

      return prev;
    }, {});
    if(Array.from(new Set(labelNumber)).length==1){
       b=true
    }else{
        let c=true
        for (const key in numObj) {
          if(numObj[key]!=2){
            c=false
          }
        }
      b=c
    }   
     if(b==cbool){
      resultData.push(item)
     }

      })
      return resultData
    },
    // 双重除取数据
    JTSCData(parData,cbool){
      let resultData = [];
      let allData = [];
      if (parData.length > 0) {
        allData = parData;
      } else {
        allData = JSON.parse(JSON.stringify(this.allData));
      }
      allData.forEach(item=>{
        let labelD = item.label.split("");
        let labelNumber = labelD.filter((lele) => this.isNumber(lele));
      let b=false;
      if(Array.from(new Set(labelNumber)).length<labelNumber.length){
        b=true
      }
       if(b==cbool){
        resultData.push(item)
       }
       })
      return resultData;
    },
    // 不定位合分 三数合

    //不定位合分 两数合 
    hefenChange(e) {
      let val=e.target.value;
      let ischecked = e.target.checked;
      if (ischecked) {
        this.condition.bdwhf = val;
      } else {
        this.condition.bdwhf = "";
      }

      this.nodwhefen = ischecked;
      // if (this.nodwhefen) {
     // this.filterAllConditionData();
      // }
    },
    // 切换配数全传除取
    pcqChange() {
      this.dcq = ""; //定位置 清空
      this.dop = 2;
      this.condition.dop = 2;
      this.resultData = [];
      this.recordData = [];
      this.selNumberData.forEach((item) => {
        item.disable = false;
        item.chuqu = "qu";
        item.arr.forEach((ele) => {
          ele.selected = false;
        });
      });
      this.yxEngArr=[]
    },
    // 不定位合分处理逻辑
    bdwhefenPanDuanBool1(label,arr,n){
      let labelD = label.split("");
      let labelNumArr = labelD.filter((lele) => this.isNumber(lele));
      let hunheArr=cmn(labelNumArr,n)
      let hunheSum=[];
      hunheArr.forEach(itemarr=>{
        let count=0;
        itemarr.forEach(ele=>{
          count+=Number(ele)
        })
        hunheSum.push(count)
      })
      let c=false;
      hunheSum.forEach((hele)=>{
      let d= arr.some((item1) => {
          let countstr = hele + "";
          return (
            countstr == item1.label ||
            countstr[countstr.length - 1] == item1.label
          );
        });
        if(d){
          c=true
        }
      })
     return c;
     
    },
    // 不定位合分筛选：1，label中数字的和是否 等于的arr中的值 2：或者 尾数和arr中的值相等
    bdwhefenPanDuanBool(label, arr) {
      let labelD = label.split("");
      let labelNumArr = labelD.filter((lele) => this.isNumber(lele));
      let count = 0;
      labelNumArr.forEach((cele) => {
        count += Number(cele);
      });
      let c = arr.some((item1) => {
        let countstr = count + "";
        return (
          countstr == item1.label ||
          countstr[countstr.length - 1] == item1.label
        );
      });
      return c;
    },
    // 定位合分筛选：1，先判断记录位置中的label[index]是否是数字 2：数字和是否等于arr中的值(尾数相等也算)
    dwhefenPanDuanBool(label, arr,str) {
      let count = 0;
      let b = true;
      this['yxIndexArr'+str].forEach((iele) => {
        if (this.isNumber(label[iele])) {
          count += Number(label[iele]);
        } else {
          b = false;
        }
      });
      let c = arr.some((item) => {
        let countstr = count + "";
        return (
          countstr == item.label || countstr[countstr.length - 1] == item.label
        );
      });
      let resultBool = false;
      if (b && c) {
        resultBool = true;
      }
      return resultBool;
    },
    multiplyData(newArrData) {
      let len = newArrData.length;
      // 当数组长度大于等于2时
      if (len >= 2) {
        // 第一个数组的长度
        let len1 = newArrData[0].length;
        // 第二个数组的长度
        let len2 = newArrData[1].length;
        //  申明一个新数组,做数据暂存
        let items = new Array();
        // 申明新数组的索引
        let index = 0;
        // 2层嵌套循环,将组合放到新数组中
        for (var i = 0; i < len1; i++) {
          for (var j = 0; j < len2; j++) {
            if (items.length < index + 1) {
              items.push([]);
            }
            if (newArrData[0][i] instanceof Array) {
              items[index] = items[index].concat(
                newArrData[0][i].concat(newArrData[1][j])
              );
            } else {
              items[index] = items[index].concat([
                newArrData[0][i],
                newArrData[1][j],
              ]);
            }
            index++;
          }
        }
        // 将新组合的数组并到原数组中
        let newArr = new Array(len - 1);
        for (let i = 2; i < newArrData.length; i++) {
          newArr[i - 1] = newArrData[i];
        }
        newArr[0] = items;
        // 执行回调
        return this.multiplyData(newArr);
      } else {
        // console.log(newArrData[0]);
        return newArrData[0];
      }
    },
    xhHefenArr(arr, henum) {
      let b = false;
      let b1 = false;
      b = arr.some((itemText) => {
        if (this.isNumber(itemText)) {
          if (henum < 10) {
            b1 = itemText == henum ? true : false;
          } else {
            let hestr = henum + "";
            b1 = itemText == hestr[1] ? true : false;
          }
        } else {
          b1 = this.pdhanz(itemText, henum);
        }
        return b1;
      });
      return b;
    },
    isContained(a, b) {
      //a是否包含b
      let numArrA = a.reduce(function (prev, next) {
        prev[next] = prev[next] + 1 || 1;

        return prev;
      }, {});
      let numArrB = b.reduce(function (prev, next) {
        prev[next] = prev[next] + 1 || 1;

        return prev;
      }, {});
      // a和b其中一个不是数组，直接返回false
      if (!(a instanceof Array) || !(b instanceof Array)) return false;
      const len = b.length;
      // a的长度小于b的长度，直接返回false
      if (a.length < len) return false;
      for (let i = 0; i < len; i++) {
        // 遍历b中的元素，遇到a没有包含某个元素的，直接返回false
        if (!a.includes(b[i])) {
          return false;
        } else {
          // 判断 a数组中包含B并且 数量要大于b数组中元素重复次数
          if (numArrA[b[i]] < numArrB[b[i]]) {
            return false;
          }
        }
      }

      // 遍历结束，返回true
      return true;
    },
    isContainedFuShi(a, b) {
      //a是否包含b
    
      // a和b其中一个不是数组，直接返回false
      if (!(a instanceof Array) || !(b instanceof Array)) return false;
      const len = b.length;
      // a的长度小于b的长度，直接返回false
      // if (a.length < len) return false;
      for (let i = 0; i < len; i++) {
        // 遍历b中的元素，遇到a没有包含某个元素的，直接返回false
        if (!a.includes(b[i])) {
          return false;
        } 
      }

      // 遍历结束，返回true
      return true;
    },
    arrange(arr){

      var result = [],
          temp = [];
      let arr1=Array.from(new Set(arr))
      arr1.sort(function(source, dest){
          return source - dest;
      }).concat(Infinity).reduce(function(source, dest){
  
          temp.push(source);
  
          if(dest-source>1){
              result.push(temp);
              temp = [];
          }
  
          return dest;
      });
  
      return result;
  
  },
    // 筛选全部条件的数据  一层一层筛选
    filterAllConditionData() {
      let resultData = [];
      let allData = JSON.parse(JSON.stringify(this.allData)); //准备筛选的数据
      // console.log(allData)
      // 先判断是 定位置 还是配数全传
      //定位置
      if (this.condition.dop == 1) {
        //选中行了
        if (this.yxEngArr.length > 0) {
          resultData = this.filterDWZData(allData);
        }
      } else if (this.condition.dop == 2) {
        //配数全传
        resultData = this.filterPSQZData(allData);
     
      }

      // 再判断是否选中了定位合分或者不定位合分
      if (this.condition.dwhf == 1) {
          if(this.yxIndexArr.length>0){
            resultData = this.JTDWHEFEN(resultData,'');
          }
         
          if(this.yxIndexArr1.length>0){
            resultData = this.JTDWHEFEN(resultData,1);
          }
          if(this.yxIndexArr2.length>0){
            resultData = this.JTDWHEFEN(resultData,2);
          }
          if(this.yxIndexArr3.length>0){
            resultData = this.JTDWHEFEN(resultData,3);
          }
      
      } 

      // console.log(this.condition.bdwhf )
      if (this.condition.bdwhf == 2||this.condition.bdwhf == 3) {
        resultData = this.JTBDWHENFEN(resultData,this.condition.bdwhf);
      }

      //  再判断 全转  上奖  排除
      if (this.condition.qz) {
        // 全转 要求 必须输入的字符长度是本页面yxlen
        resultData = this.JTQZData(resultData);
      }
      // 上奖
      if (this.condition.sj) {
        resultData = this.JTSJData(resultData);
      }
      // 排除
      if (this.condition.pc) {
        resultData = this.JTPCData(resultData, false);
      }
      // 范围1 2 合值范围
     
      if(this.condition.fw1||this.condition.fw2){
        let f1=this.condition.fw1,f2=this.condition.fw2;
      
       if(f1&&f2){
        if(f1<=f2){
          resultData=this.JTFWData(resultData,f1,f2)
        }
       }else if(f1){
        f2=f1
        resultData=this.JTFWData(resultData,f1,f2)
       }else if(f2){
        f1=f2;
        resultData=this.JTFWData(resultData,f1,f2)
       }
      
      }
      // 乘号位置
      if (this.chwzIndexArr1.length == this.chLen) {
        resultData = this.JTCHWZData(resultData);
      }
         // 含 除取
      if(this.condition.haninput!=''){
    
       if(this.condition.hancq==''||this.condition.hancq=='qu'){
        resultData=this.JTHANCHUQUData(resultData,true)
       }
       if(this.condition.hancq=='chu'){
        resultData=this.JTHANCHUQUData(resultData,false)
       }

      }
      // 复式 除取
      if(this.condition.fushiinput!=''){
        if(this.condition.hancq==''||this.condition.hancq=='qu'){
         resultData= this.JTFUSHIData(resultData,true)
        }
        if(this.condition.hancq=='chu'){
          resultData= this.JTFUSHIData(resultData,false)
        }
      }
      // 双重 除取
      if(this.condition.sc=='qu'){
    
       resultData= this.JTSCData(resultData,true)
      }else if(this.condition.sc=='chu'){
        resultData= this.JTSCData(resultData,false)
      }
      // 双双重 除取
       if(this.condition.ssc=='qu'){
        resultData=this.JTSSCData(resultData,true)
       }else if(this.condition.ssc=='chu'){
        resultData=this.JTSSCData(resultData,false)
       }
      // 三重
      if(this.condition.sanchong=='qu'){
         resultData=this.JTSANCHONGData(resultData,true)
      }else if(this.condition.sanchong=='chu'){
        resultData=this.JTSANCHONGData(resultData,false)
      }
      // 四重
      if(this.condition.sichong=='qu'){
        resultData=this.JTSICHONGData(resultData,true)
      }else if(this.condition.sichong=='chu'){
        resultData=this.JTSICHONGData(resultData,false)
      }
    //  二兄弟 取
    if(this.condition.xd2=='qu'){
     resultData=this.JTDXData(resultData,true,2)
    }
     //  二兄弟 除
     if(this.condition.xd2=='chu'){
      resultData=this.JTDXData(resultData,false,2)
     }
    //  三兄弟 取
    if(this.condition.xd3=='qu'){
      resultData=this.JTDXData(resultData,true,3)
     }
      //  三兄弟 除
      if(this.condition.xd3=='chu'){
       resultData=this.JTDXData(resultData,false,3)
      }
      // 四兄弟 取
      if(this.condition.xd4=='qu'){
        resultData=this.JTDXData(resultData,true,4)
       }
       if(this.condition.xd4=='chu'){
        resultData=this.JTDXData(resultData,false,4)
       }
    //  对数还是输入框对数
   
    if(!this.isds){
      let numArr=[];
      if(this.panduanDsinput(this.condition.dsinput1)){
        numArr.push(this.condition.dsinput1)  
      }
      if(this.panduanDsinput(this.condition.dsinput2)){
        numArr.push(this.condition.dsinput2)  
      }
      if(this.panduanDsinput(this.condition.dsinput3)){
        numArr.push(this.condition.dsinput3)  
      }
      if(this.panduanDsinput(this.condition.dsinput4)){
        numArr.push(this.condition.dsinput4)  
      }
      if(this.panduanDsinput(this.condition.dsinput5)){
        numArr.push(this.condition.dsinput5)  
      }
     
     if(numArr.length>0){
      // 对数input框 取
       if(this.condition.ds=='qu'||this.condition.ds==''){
      resultData=this.JTDSINPUTData(resultData,numArr,true)
       }
      //  对数输入框 除
      if(this.condition.ds=='chu'){
        resultData=this.JTDSINPUTData(resultData,numArr,false)
      }
     }

    }else{
   
      // 对数 除取
      if(this.condition.ds=='qu'){
      resultData= this.JTDSData(resultData,true)
      }
      if(this.condition.ds=='chu'){
        resultData= this.JTDSData(resultData,false)
        }
    }
    // 单 取
      if(this.condition.dancq=='qu'||this.condition.dancq==''){
        //四字定
        if(this.yxLen==4||this.isXian){
         if(this.danIndexArr.length>0){
          resultData=this.JTDanData(resultData,true)
         }
        }else{
          if(this.danKey||this.isXian){
            console.log(666)
            resultData=this.JTDanData(resultData,true)
          }
        }
     
      }
       // 单 除
       if(this.condition.dancq=='chu'){
        //四字定
        if(this.yxLen==4||this.isXian){
          if(this.danIndexArr.length>0){
           resultData=this.JTDanData(resultData,false)
          }
         }else{
           if(this.danKey){
             resultData=this.JTDanData(resultData,false)
           }
         }
      }
      // 双 取
      if(this.condition.shuangcq=='qu'||this.condition.shuangcq==''){
        // 四字定
        if(this.yxLen==4||this.isXian){
          if(this.shuangIndexArr.length>0){
            resultData=this.JTShuangData(resultData,true)
          }
        }else{
          if(this.shuangKey){
            resultData=this.JTShuangData(resultData,true)
            }
        }
       
      }
      // 双 除
      if(this.condition.shuangcq=='chu'){
        // 四字定
        if(this.yxLen==4||this.isXian){
          if(this.shuangIndexArr.length>0){
            resultData=this.JTShuangData(resultData,false)
          }
        }else{
          if(this.shuangKey){
            resultData=this.JTShuangData(resultData,false)
            }
        }
      }
      // 小 取
      if(this.condition.xiaocq=='qu'||this.condition.xiaocq==''){
        // 四字定
        if(this.yxLen==4||this.isXian){
          if(this.xiaoIndexArr.length>0){
            resultData=this.JTXiaoData(resultData,true)
          }
        }else{
          if(this.xiaoKey){
            resultData=this.JTXiaoData(resultData,true)
          }
        }
      
      }
      // 小 除
      if(this.condition.xiaocq=='chu'){
           // 四字定
           if(this.yxLen==4||this.isXian){
            if(this.xiaoIndexArr.length>0){
              resultData=this.JTXiaoData(resultData,false)
            }
          }else{
            if(this.xiaoKey){
              resultData=this.JTXiaoData(resultData,false)
            }
          }
      }
      // 大 取
      if(this.condition.dacq=='qu'||this.condition.dacq==''){
        // 四字定
        if(this.yxLen==4||this.isXian){
          if(this.daIndexArr.length>0){
            resultData=this.JTDaData(resultData,true)
          }
        }else{
          if(this.daKey){
            resultData=this.JTDaData(resultData,true)
          }
        }
       
      }
      // 大 除
      if(this.condition.dacq=='chu'){
        // 四字定
        if(this.yxLen==4||this.isXian){
          if(this.daIndexArr.length>0){
            resultData=this.JTDaData(resultData,false)
          }
        }else{
          if(this.daKey){
            resultData=this.JTDaData(resultData,false)
          }
        }
      }
      // ------------------------------
      // console.log(resultData,'this.resultData')
      this.resultData = resultData;
    
      //end --------------------------------
    },
    resetAllCondition(){
      if(this.condition){
        for (const key in this.condition) {
          if(key!='dop'){
            this.condition[key]=''
          }
        }
       
   
      }
      if(this.isXian){
        this.condition.dop=2;
        this.pcq = "qu"
      }
      this.sdanLabel='',
      this.sshuangLabel='',
      this.sxiaoLabel='',
      this.sdaLabel='',
      this.danxianlabel='',
      this.shuangxianlabel='',
      this.xiaoxianlabel='',
      this.daxianlabel='',
      this.daKey='',
      this.daIndexArr=[],
      this.daArr=[
        {selected:false,disable:false,name:'qian'},
        {selected:false,disable:false,name:'bai'},
        {selected:false,disable:false,name:'shi'},
        {selected:false,disable:false,name:'ge'},
      ]
      this.xiaoKey='',
      this.xiaoIndexArr=[],
      this.xiaoArr=[
        {selected:false,disable:false,name:'qian'},
        {selected:false,disable:false,name:'bai'},
        {selected:false,disable:false,name:'shi'},
        {selected:false,disable:false,name:'ge'},
      ],
      this.shuangKey='',
      this.shuangIndexArr=[],
      this.shuangArr=[
        {selected:false,disable:false,name:'qian'},
        {selected:false,disable:false,name:'bai'},
        {selected:false,disable:false,name:'shi'},
        {selected:false,disable:false,name:'ge'},
      ],
      this.danKey='',
      this.danIndexArr=[],
      this.danArr=[
        {selected:false,disable:false,name:'qian'},
        {selected:false,disable:false,name:'bai'},
        {selected:false,disable:false,name:'shi'},
        {selected:false,disable:false,name:'ge'},
    ],
    this.selNumberData.forEach(item=>{
      item.disable=false;
      item.arr.forEach(ele=>{
        ele.selected=false
      })
    })
    this.selNumberInput.forEach(item=>{
      item.numbervalue=''
    })
    this.selNumberData1.forEach(item=>{
      
      item.arr.forEach(ele=>{
        ele.selected=false
      })
    })
    this.selNumberInput1.forEach(item=>{
      item.numbervalue=''
    })
    // -------------------------------------------
    this.dwhenfenNumArr.forEach(item=>{
      item.selected=false
    })
    this.dwhenfennumInput=''
        // -------------------------------------------
       
    this.dwhenfenNumArr1.forEach(item=>{
      item.selected=false
    })
    this.dwhenfennumInput1=''
        // -------------------------------------------
        this.dwhenfenNumArr2.forEach(item=>{
          item.selected=false
        })
        this.dwhenfennumInput2=''
            // -------------------------------------------
            this.dwhenfenNumArr3.forEach(item=>{
              item.selected=false
            })
            this.dwhenfennumInput3=''
                // -------------------------------------------
    this.bdwhenfenNumArr.forEach(item=>{
      item.selected=false
    })
    this.bdwhenfennumInput=''

   this.yxEngArr=[]
   this.recordData= []
   this.dwhefenArr.forEach(item=>{
    item.selected=false
    item.disable=false
  })
  this.dwhefenArr1.forEach(item=>{
    item.selected=false
    item.disable=false
  })
  this.dwhefenArr2.forEach(item=>{
    item.selected=false
    item.disable=false
  })
  this.dwhefenArr3.forEach(item=>{
    item.selected=false
    item.disable=false
  })
   this.yxIndexArr=[];
  this.chwzIndexArr=[];
  this.chwzIndexArr1=[]
  this.nodwhefen=false;
   this.hefenNum='';
  this.chwzArr.forEach(item=>{
    item.selected=false
    item.disable=false
  })


      this.resultData=[]
      
    }
  },
};
export default mixins;
